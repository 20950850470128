<template>
  <div class="home">
    <van-form @submit="onSubmit">


      <van-field name="radio" label="合作方式">
        <template #input>
          <van-radio-group v-model="radio" direction="horizontal">
            <van-radio name="1">内容合作</van-radio>
            <van-radio name="2">商务合作</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        v-model="username"
        name="姓名"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />


      <van-field
        v-model="username"
        name="手机号"
        label="手机号"
        placeholder="手机号"
        :rules="[{ required: true, message: '请填写手机号' }]"
      />


      <van-field
        v-model="username"
        name="合作内容"
        label="合作内容"
        placeholder="合作内容"
        :rules="[{ required: true, message: '请填写合作内容' }]"
      />


   

      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return { username: "", password: "" };
  },
  components: {},
};
</script>
